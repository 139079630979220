<template>
  <div id="bg">
    <div class="search">
      <div class="search_box">
        关键字: <el-input class="width_200" v-model="search.keyword" placeholder="请输入关键字"></el-input>
        <el-button type="primary" size="medium" @click="showDialog()">添加</el-button>
        <el-button type="primary" size="medium" @click="getList()">查询</el-button>
      </div>
      <div class="search_box">
        <p style="padding: 0;margin:0; line-height: 16px;">
          默认检测数字:&nbsp;&nbsp;[0-9一二三四五六七八九十零①②③④⑤⑥⑦⑧⑨oO壹贰叁肆伍陆柒捌玖拾ⅠⅡⅢⅣⅤⅥⅦⅧⅨⅩIXVixv]
        </p>
        <p style="padding: 0;margin:0;line-height: 16px;">
          默认检测域名:&nbsp;&nbsp;[cn,com,net,cc,live]</p>
      </div>
    </div>
    <el-table :data="tableData" :header-cell-style="{background:'#F7F8FA'}">
      <el-table-column prop="id" label="ID" align="center" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="keyword" label="关键字" align="center" :show-overflow-tooltip="true">
        <template #default="scope">
          {{decodeURIComponent(scope.row.keyword)}}
        </template>
      </el-table-column>
      <el-table-column prop="addtime" label="添加时间" align="center" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column label="操作" align="center">
        <template #default="scope">
          <span class="operation" @click="showDialog(scope.row)">编辑</span>
          <span class="operation" @click="del(scope.row)" >删除</span>
        </template>
      </el-table-column>
    </el-table>
    <page :pager="pager" v-if="pager.total > 0" @query="getList()" />

    <el-dialog :title="dialogTitle" v-model="dialogVisible" width="600" height="500px" top="5vh" :close-on-click-modal="false" >
      <el-row>
        <el-col :span="4" align="right">关键字</el-col>
        <el-col :span="20">
          <el-input placeholder="请输入关键字,多个关键字逗号隔开" v-model="dialogAdd.keyword"></el-input>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="4"></el-col>
        <el-col :span="20">
          <el-button @click="dialogVisible=false">取消</el-button>
          <el-button type="primary" @click="save()">保存</el-button>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="4"></el-col>
        <el-col :span="20">
        </el-col>
      </el-row>
    </el-dialog>
  </div>
</template>

<script>
import httpClient from "@/config/httpClient";
import page from "@/components/page";
export default {
  name: "community_keyword",
  components: {
    page,
  },
  data(){
    return {
      search:{
        type:-1,
      },
      pager: { total: 0, page: 1, rows: 20 },
      tableData:[
      ],
      dialogVisible:false,
      dialogTitle:"添加关键字",
      dialogAdd:{
        id:0,
        keyword:"",
      },
    }
  },
  mounted() {
    this.getList();
  },
  methods:{
    del(row){
      this.$confirm("删除关键字: " + row.keyword + "吗？", "提示", { confirmButtonText: "确定", cancelButtonText: "取消", type: "warning" }).then(()=>{
        httpClient("keywordDel").post({id:row.id}).then((res)=>{
          if(res.code == 0){
            this.getList()
          }else{
            this.$message({message:res.msg,type:"error"})
          }
        })
      })
    },
    getList(){
      httpClient("keywordList").post({}).then((res)=>{
        if (res.code == 0){
          this.tableData = res.data.list
          if (this.pager.page == 1){
            this.pager.total = res.data.total
          }
        }else{
          this.$message({ message: res.msg, type: "error" });
        }
      },(err)=>{
        console.log(err)
      })
    },
    showDialog(row){
      this.dialogVisible = true
      this.dialogAdd = {
        id:0,
        keyword:"",
      }
      if (row){
        this.dialogTitle = "编辑关键字"
        this.dialogAdd = {
          id:row.id,
          keyword:decodeURIComponent(row.keyword),
        }
      }else{
        this.dialogTitle = "添加关键字"
      }
    },
    save(){
      // 保存
      if(this.dialogAdd.keyword == ""){
        this.$message({ message: "请输入关键字", type: "error" });
        return;
      }
      if (this.dialogAdd.id == 0){
        // 添加
        httpClient("keywordAdd").post({keyword:this.dialogAdd.keyword}).then((res)=>{
          if (res.code == 0){
            this.dialogVisible = false
            this.$message({ message: "添加成功", type: "success" });
            this.getList()
          }else{
            this.$message({ message: res.msg, type: "error" });
          }
        },(err)=>{
          console.log(err)
        })
      }else{
        // 修改
        httpClient("keywordEdit").post({keyword:this.dialogAdd.keyword,id:this.dialogAdd.id}).then((res)=>{
          if (res.code == 0){
            this.dialogVisible = false
            this.getList()
          }else{
            this.$message({ message: res.msg, type: "error" });
          }
        },(err)=>{
          console.log(err)
        })
      }
    }
  }
}
</script>

<style scoped>
.el-row{
  margin-bottom: 5px;
}
.el-row .el-col{
  padding-right: 5px;
}
</style>